




















import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component({})
export default class djlFileUpload extends Vue {
  @Prop()
  public name!: string;

  @Prop({ default: "Selecteer bestand(en)" })
  public label?: string;

  @Prop({ default: false })
  public required?: boolean;

  @Prop()
  public accept?: string;

  @Ref() readonly files!: HTMLInputElement;

  private isValid = false;
  private validityChecked = false; //becomes true when trying to submit, false again when value of multiselect changed

  filesSelected(): void {
    this.validityChecked = false;
    this.$emit("input", this.files.files);
  }

  resetValue() {
    this.files.value = "";
    this.filesSelected();
  }

  validate(): boolean {
    this.validityChecked = true;

    if (this.required) {
      this.isValid =
        ((this.$refs.files as HTMLInputElement).files as FileList).length > 0;
    } else {
      this.isValid = true;
    }

    return this.isValid;
  }

  get computedName(): string {
    return this.name.toLowerCase().split(" ").join("-");
  }
}
